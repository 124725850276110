import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_POSTS } from '../graphql/query/posts';
import { CREATE_POST, LIKE_POST, CREATE_COMMENT } from '../graphql/mutations/posts';
import { useWallet } from '@alephium/web3-react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  styled,
  InputAdornment,
  Grid,
  CircularProgress,
  IconButton as MUIIconButton,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Sort, Public, Group, ArrowDropDown, Close as CloseIcon } from '@mui/icons-material';
import PostCard from './PostCard';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { motion } from "framer-motion";

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  position: 'relative',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#fff',
}));

const PostsWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  '-webkit-overflow-scrolling': 'touch', // Enables momentum scrolling on iOS
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  marginTop: theme.spacing(2),
}));

// Slide Transition for Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PostComponent = () => {
  const { account } = useWallet();
  const { address } = account || {};
  const [order, setOrder] = useState('DESC');
  const [authorFilter, setAuthorFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [offset, setOffset] = useState(0);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [newPostContent, setNewPostContent] = useState('');
  const [commentContent, setCommentContent] = useState('');
  const [currentPostId, setCurrentPostId] = useState('');
  const [expandedPosts, setExpandedPosts] = useState({});
  const [privacy, setPrivacy] = useState('public');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const limit = 10;
  const [previousQuery, setPreviousQuery] = useState({});
  const postsWrapperRef = useRef();
  const [paid, setPaid] = useState(false); // New state for paid post
  const [valueToPay, setValueToPay] = useState('');
  const [getPosts, { data, loading, error, fetchMore, refetch }] = useLazyQuery(GET_POSTS, {
    variables: { order, author: authorFilter, search: searchTerm, offset, limit },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const [createPost] = useMutation(CREATE_POST);
  const [likePost] = useMutation(LIKE_POST);
  const [createComment] = useMutation(CREATE_COMMENT);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getPosts();
  }, [getPosts]);

  const handleCreatePost = async () => {
    try {
      await createPost({
        variables: {
          createPostInput: {
            content: newPostContent,
            author: address,
            privacy: privacy,

          },
        },
      });
      setNewPostContent('');
      setPaid(false); // Reset paid state
      setValueToPay(''); // Reset valueToPay
      refetch();
      setShowCreatePost(false);
    } catch (err) {
      console.error("Error creating post:", err);
    }
  };

  const toggleOrder = () => {
    const newOrder = order === 'DESC' ? 'ASC' : 'DESC';
    setOrder(newOrder);
    setOffset(0);
    const newQuery = { order: newOrder, author: authorFilter, search: searchTerm, offset: 0, limit };
    if (!isEqual(previousQuery, newQuery)) {
      setPreviousQuery(newQuery);
      getPosts({ variables: newQuery });
    }
  };

  const refetchPostsAndRestoreScroll = async (scrollPosition) => {
    await refetch();
    if (postsWrapperRef.current) {
      postsWrapperRef.current.scrollTop = scrollPosition;
    }
  };

  const handleLikePost = async (postId) => {
    const scrollPosition = postsWrapperRef.current.scrollTop;
    await likePost({
      variables: {
        likePostInput: {
          postId,
          user: address,
        },
      },
    });
    refetchPostsAndRestoreScroll(scrollPosition);
  };

  const handleCreateComment = async (postId) => {
    const scrollPosition = postsWrapperRef.current.scrollTop;
    await createComment({
      variables: {
        createCommentInput: {
          postId,
          content: commentContent,
          user: address,
        },
      },
    });
    setCommentContent('');
    setCurrentPostId('');
    refetchPostsAndRestoreScroll(scrollPosition);
  };

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        offset: data?.posts.length,
        limit,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          posts: [...prev?.posts, ...fetchMoreResult?.posts],
        };
      },
    });
  };

  const [manualLoadMore, setManualLoadMore] = useState(false);

  const handleScroll = useCallback(
    debounce((e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (scrollHeight - scrollTop <= clientHeight + 1) {
        handleLoadMore();
        setManualLoadMore(false);
      } else {
        setManualLoadMore(true);
      }
    }, 300),
    [handleLoadMore]
  );

  useEffect(() => {
    return () => {
      handleScroll.cancel();
    };
  }, [handleScroll]);

  const handleClickOpen = () => {
    setShowCreatePost(true);
  };

  const handleClose = () => {
    setShowCreatePost(false);
  };

  const handleRefresh = async () => {
    await refetch();
  };

  if (error) return <Typography>Error: {error.message}</Typography>;

  return (
    <StyledContainer maxWidth="lg">
      {isMobile ? (
        <Box width="100%" mb={2}>
          <Button
            color="primary"
            onClick={handleClickOpen}
            fullWidth
            sx={{
              backgroundColor: 'rgba(30,30,30,0.5)',
              color: '#fff',
              '&:hover': {
                backgroundColor: 'rgba(50,50,50,0.7)',
              },
            }}
          >
            Create Post
          </Button>
        </Box>
      ) : (
        <Box width="100%" mb={2}>
          {/* Privacy Selection and New Post TextField for Desktop */}
          <FormControl sx={{ minWidth: 120, mb: 2 }}>
            <Select
              value={privacy}
              onChange={(e) => setPrivacy(e.target.value)}
              disableUnderline
              variant="standard"
              sx={{
                fontSize: '0.9rem',
                color: '#6C7A89',
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: 'rgba(50,50,50,0.1)',
                },
              }}
              renderValue={(selected) => (
                <Box display="flex" alignItems="center">
                  {selected === 'public' && <Public sx={{ color: '#6C7A89', mr: 1 }} />}
                  {selected === 'followers' && <Group sx={{ color: '#6C7A89', mr: 1 }} />}
                  <Typography variant="inherit" sx={{ color: '#6C7A89' }}>
                    {selected === 'public' ? 'Public' : 'Followers Only'}
                  </Typography>
                </Box>
              )}
              IconComponent={ArrowDropDown}
              inputProps={{
                sx: {
                  color: '#6C7A89',
                  padding: '0',
                },
              }}
            >
              <MenuItem value="public">
                <ListItemIcon>
                  <Public fontSize="small" sx={{ color: '#6C7A89' }} />
                </ListItemIcon>
                <Typography variant="inherit">Public</Typography>
              </MenuItem>
              <MenuItem value="followers" disabled>
                <ListItemIcon>
                  <Group fontSize="small" sx={{ color: '#6C7A89' }} />
                </ListItemIcon>
                <Typography variant="inherit">Followers Only (Coming Soon)</Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            placeholder="What's on your mind?"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={newPostContent}
            onChange={(e) => setNewPostContent(e.target.value)}
            InputProps={{
              style: {
                backgroundColor: 'rgba(30,30,30,0.5)',
                color: 'white',
              },
            }}
            sx={{
              mb: 2,
              '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: '#007bff',
              },
            }}
          />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              onClick={handleCreatePost}
              sx={{
                marginTop: '15px',
                textTransform: 'none',
                backgroundColor: '#007bff',
                color: '#fff',
                border: `2px solid #007bff`,
                borderRadius: '30px',
                padding: '10px 30px',
                fontWeight: 'bold',
                boxShadow: '0 0 10px rgba(0, 123, 255, 0.5)',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: '#007bff',
                  color: '#fff',
                  boxShadow: '0 0 20px rgba(0, 123, 255, 0.7)',
                },
              }}
            >
              Post
            </Button>
          </Box>
        </Box>
      )}

      {/* Create Post Dialog for Mobile */}
      {isMobile && (
        <Dialog
          fullScreen
          open={showCreatePost}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            sx: {
              backgroundColor: '#1e1e1e',
            },
          }}
        >
          <AppBar sx={{ position: 'relative', backgroundColor: '#1e1e1e', boxShadow: 'none', color: '#fff' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Box flexGrow={1} />
              <Button
                onClick={handleCreatePost}
                sx={{
                  color: '#007bff',
                  textTransform: 'none',
                  padding: '6px 16px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                  },
                }}
              >
                Post
              </Button>
            </Toolbar>
          </AppBar>
          <Container sx={{ mt: 2, color: 'white' }}>
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
              <FormControl sx={{ minWidth: 120 }}>
                <Select
                  value={privacy}
                  onChange={(e) => setPrivacy(e.target.value)}
                  disableUnderline
                  variant="standard"
                  sx={{
                    fontSize: '0.9rem',
                    color: '#6C7A89',
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: 'rgba(50,50,50,0.1)',
                    },
                  }}
                  renderValue={(selected) => (
                    <Box display="flex" alignItems="center">
                      {selected === 'public' && <Public sx={{ color: '#6C7A89', mr: 1 }} />}
                      {selected === 'followers' && <Group sx={{ color: '#6C7A89', mr: 1 }} />}
                      <Typography variant="inherit" sx={{ color: '#6C7A89' }}>
                        {selected === 'public' ? 'Public' : 'Followers Only'}
                      </Typography>
                    </Box>
                  )}
                  IconComponent={ArrowDropDown}
                  inputProps={{
                    sx: {
                      color: '#6C7A89',
                      padding: '0',
                    },
                  }}
                >
                  <MenuItem value="public">
                    <ListItemIcon>
                      <Public fontSize="small" sx={{ color: '#6C7A89' }} />
                    </ListItemIcon>
                    <Typography variant="inherit">Public</Typography>
                  </MenuItem>
                  <MenuItem value="followers" disabled>
                    <ListItemIcon>
                      <Group fontSize="small" sx={{ color: '#6C7A89' }} />
                    </ListItemIcon>
                    <Typography variant="inherit">Followers Only (Coming Soon)</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <TextField
              placeholder="What do you want to talk about?"
              variant="standard"
              fullWidth
              multiline
              rows={4}
              value={newPostContent}
              onChange={(e) => setNewPostContent(e.target.value)}
              InputProps={{
                disableUnderline: true,
                style: {
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: 'rgba(255, 255, 255, 0.6)',
                  fontSize: '1.25rem',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiInputBase-root': {
                  padding: 0,
                },
                '&:hover': {
                  backgroundColor: 'rgba(50,50,50,0.1)',
                },
              }}
            />
          </Container>
        </Dialog>
      )}

      {/* Search Bar */}
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" mb={2}>
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        > <TextField
            label="Search Posts"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MUIIconButton>
                    <SearchIcon style={{ color: 'white' }} />
                  </MUIIconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              marginBottom: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#333',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#999',
                },
              },
              '& .MuiInputBase-input': {
                color: '#fff',
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(255, 255, 255, 0.7)',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#fff',
              },
            }}
          /></motion.div>
      </Box>

      {/* Order Toggle */}
      <Grid container width="100%" direction="row" alignItems="center" justifyContent="flex-start">
        <Grid item>
          <Sort
            onClick={toggleOrder}
            sx={{
              color: 'white',
              cursor: 'pointer',
              '&:hover': {
                color: '#007bff',
              },
            }}
          />
        </Grid>
      </Grid>

      {/* Posts List with Pull-to-Refresh */}
      <PullToRefresh onRefresh={handleRefresh}>
        <PostsWrapper ref={postsWrapperRef} onScroll={handleScroll}>
          {!loading && data?.posts?.length === 0 && <Typography>Nothing yet 😊</Typography>}
          {data?.posts?.length > 0 &&
            data.posts.map((post, index) => (
              <Box
                key={post.id}
                sx={{
                  '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
                  },
                }}
              >
                <motion.div
                  key={post.id}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1, duration: 0.3 }}

                >
                  <PostCard
                    post={post}
                    currentPostId={currentPostId}
                    setCurrentPostId={setCurrentPostId}
                    handleLikePost={handleLikePost}
                    handleCreateComment={handleCreateComment}
                    commentContent={commentContent}
                    setCommentContent={setCommentContent}
                  />
                </motion.div>
              </Box>

            ))}
          {manualLoadMore && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                onClick={handleLoadMore}
                variant="contained"
                sx={{
                  backgroundColor: '#333',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#555',
                  },
                }}
              >
                Load More
              </Button>
            </Box>
          )}
        </PostsWrapper>
      </PullToRefresh>
    </StyledContainer>
  );
};

export default PostComponent;

